import request from "@/utils/request";
import departmentApi from '@/const/apiUrl/express';
import method from "@/const/request/requestMethod";

//快递公司列表查询
export function expressList(searchData) {
    return request({
        url: departmentApi.expressList,
        method: method.GET,
        data: searchData
    })
}
//删除快递公司
export function expressDel(params) {
    return request({
        url: departmentApi.expressDel,
        method: method.GET,
        data: params
    })
}
//新建快递公司
export function expressAdd(params) {
    return request({
        url: departmentApi.expressAdd,
        method: method.POST,
        data: params
    })
}
//编辑快递公司
export function expressEdit(params) {
    return request({
        url: departmentApi.expressEdit,
        method: method.POST,
        data: params
    })
}
// 根据id获取快递公司详情
export function expressEditId(params){
    return request({
        url: departmentApi.expressEditId,
        method: method.GET,
        data: params
    })
}