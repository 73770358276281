const expressApi = {
    //快递公司配置列表
    expressList: '/setting/express/list',
    //删除快递公司
    expressDel: '/setting/express/delete',
    //新增快递公司
    expressAdd: '/setting/express/add',
    //编辑快递公司
    expressEdit: '/setting/express/edit',
    //根据id获取快递公司配置
    expressEditId: '/setting/express/getById'
}


export default expressApi